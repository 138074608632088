import * as Yup from 'yup';
import { FrontType } from '../@types/Kpi';
export var kpiValidation = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    label: Yup.string().required('Label is required'),
    frontType: Yup.string()
        .required('Front type is required')
        .oneOf(Object.values(FrontType), 'Invalid front type value'),
    isRequired: Yup.boolean().required('isRequired is required'),
    choices: Yup.array().of(Yup.mixed()),
});
